<template>
  <main class="background">
    <div class="bg-particles-top grid h-screen grid-cols-2 overflow-hidden">
      <div class="col-span-full h-screen lg:col-span-1">
        <div class="flex h-full flex-col justify-between pt-5 lg:pt-20">
          <div class="bg-particles-bot h-full overflow-auto px-4 lg:px-18">
            <div class="mb-3 flex w-full justify-between lg:mb-10">
              <div class="shrink-0 self-center"><BackHome /></div>
              <div class="shrink-0 self-center">
                <ButtonCTA :to="'/contact'" label="Contact us" class="relative" />
              </div>
            </div>
            <slot name="content" />
          </div>
          <slot name="next" />
        </div>
      </div>
      <div class="relative col-span-full h-screen lg:col-span-1">
        <slot name="media" />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import locales from '~/i18n/locales'

const { locale } = useI18n()
const langAttr = computed(() => locales.find((l) => l.code === locale.value).code)

useHead({
  htmlAttrs: {
    lang: langAttr
  }
})
</script>

<style lang="postcss" scoped>
.background {
  background: url('~/assets/images/backgrounds/detail-top-bg.webp') 0 0 / 1440px 259px no-repeat;
}
</style>
